// Module Import
import React, { FC, Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

// Component Import
import { useSelector } from "react-redux";
import "./App.less";
import APILoader from "./components/APILoader/APILoader";
import GlobalLoader from "./components/GlobalLoader/GlobalLoader";
import { getServiceWithToken } from "./services/httpServices";

// Suspense Imports
const Login = React.lazy(() => import("./pages/Login/Login"));
const Home = React.lazy(() => import("./pages/home/Home"));
const Solution = React.lazy(() => import("./pages/solutions/Solution"));
const PlansPage = React.lazy(() => import("./pages/Subscription/Subscription"));
const BillingDetails = React.lazy(
  () => import("./pages/BillingDetails/BillingDetails")
);
const ForgotPassword = React.lazy(
  () => import("./pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(
  () => import("./pages/ResetPassword/ResetPassword")
);
const SignupVerify = React.lazy(() => import("./pages/SignUp/SignupVerify"));
const Subscription = React.lazy(
  () => import("./pages/Subscription/Subscription")
);
const Enrollment = React.lazy(() => import("./pages/Onboarding/Enrollment"));
const SignUpSuccess = React.lazy(
  () => import("./pages/Onboarding/SignUpSuccess")
);
const PrivateOutlet = React.lazy(() => import("./routes/PrivateOutlet"));

const App: FC = () => {
  const title = useSelector((state: any) => state.title);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // if (token) {
    //   navigate("/dashboard/welcome");
    // }
  }, []);

  return (
    <Suspense fallback={<GlobalLoader />}>
      <APILoader />

      <Routes>
        <Route path="/" element={<Home/> } />
        <Route path="/solutions" element={<Solution />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<PlansPage />} />
        <Route path="/verify" element={<SignupVerify />} />
        <Route path="/enrollment" element={<Enrollment />} />
        <Route path="/billing-details" element={<BillingDetails />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/sign-up/verify-otp" element={<SignupVerify />} />
        <Route path="/sign-up/subscription" element={<Subscription />} />
        <Route path="/sign-up/enroll" element={<Enrollment />} />
        <Route path="/sign-up/success" element={<SignUpSuccess />} />

        <Route
          path="/dashboard/*"
          element={
            <Suspense fallback={<GlobalLoader />}>
              <PrivateOutlet />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default App;
