import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ParentState {
  shouldCallParentMethod: boolean;
}

const initialState: ParentState = {
  shouldCallParentMethod: false,
};

const parentSlice = createSlice({
  name: 'parent',
  initialState,
  reducers: {
    signalParentMethodCall: (state) => {
      state.shouldCallParentMethod = true;
    },
    resetParentMethodCall: (state) => {
      state.shouldCallParentMethod = false;
    },
  },
});

export const { signalParentMethodCall, resetParentMethodCall } = parentSlice.actions;

export const selectShouldCallParentMethod = (state: any) => state.parent.shouldCallParentMethod;

export default parentSlice.reducer;
