import React from 'react'
import { Spin } from "antd";

interface LoadingSpinnerProps {
    minHeight?:  string;  
  }

const LoadingSpinner:React.FC<LoadingSpinnerProps> = ({minHeight='200px'}) => {
    const spinnerStyle: any = {
        width: '100%',
        height: '100%', 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        zIndex: 5000,
        minHeight
    };
    return (
        <div style={spinnerStyle}>
            <Spin className='inter'
                style={{
                    fontSize: '1rem'
                }}
                size="large" tip="Loading..." />
        </div>
    )
}

export default LoadingSpinner
