import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<object>) => {
      return (state = action.payload);
    },
  },
});
// export the user slice
export const { setUser } = userSlice.actions;
export default userSlice.reducer;
