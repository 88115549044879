import { rolesApi } from "./../services/roles";
import { purchaseApi } from "./../services/purchase";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { plansApi } from "../services/plans";
import titleSlice from "../redux/titleSlice";
import loaderSlice from "../redux/loaderSlice";
import userSlice from "../redux/userSlice";
import userCreateSlice from "../redux/userCreateSlice";
import positionSlice from "../redux/positionSlice";
import rolesSlice from "../redux/roleSlice";
import taskReallocationSlice from "../redux/taskReallocationSlice";
import parentReducer from '../redux/parentSlice';


export const store = configureStore({
  reducer: {
    [plansApi.reducerPath]: plansApi.reducer,
    [purchaseApi.reducerPath]: purchaseApi.reducer,
    [rolesApi.reducerPath]: rolesApi.reducer,
    title: titleSlice,
    loader: loaderSlice,
    user: userSlice,
    userCreate: userCreateSlice,
    position: positionSlice,
    role: rolesSlice,
    taskReallocation:taskReallocationSlice,
    parent: parentReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(plansApi.middleware, purchaseApi.middleware, rolesApi.middleware),
    
});
export type AppDispatch = typeof store.dispatch;
