import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apis from "../apis/url";
export const plansApi = createApi({
  reducerPath: "plan?page_number=1&page_size=10&search&order_by=price",
  baseQuery: fetchBaseQuery({
    baseUrl: apis.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // const token = (getState() as RootState).auth.token
      const token = JSON.parse(localStorage.getItem("OnTym-Token") || "{}");
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllPlans: builder.query<any, void>({
      query: () => ({
        url: "/plan?page_number=1&page_size=10&search&order_by=price",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetAllPlansQuery } = plansApi;
