import { createSlice } from "@reduxjs/toolkit";

// create a slice for loader

export const loaderSlice = createSlice({
  name: "loader",
  initialState: 0,
  reducers: {
    setLoading: (state) => {
      return (state = state + 1);
    },
    removeLoading: (state) => {
      return (state = state - 1);
    },
  },
});

export const { setLoading, removeLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
