import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import apis from "./apis/url";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>,
  document.getElementById("root")
);


function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}


const VAPID_PUBLIC_KEY = 'BPVPYHyo75tIGeF6iOw9ns3wITYX3tGQw8MZKCyortvBgfAzpHUVQoyp6LPK0dHFsWJ2d1Nh2n82s-fmvKs4UOs'


// Check if the browser supports service workers
    
if ("serviceWorker" in navigator) {  
  // Define an asynchronous function to handle service worker registration and push notification subscription
  const handleServiceWorker = async () => {
    // Register the service worker located at "/sw.js"
    const register = await navigator.serviceWorker.register("/sw.js");


    // Subscribe to push notifications using the PushManager
    const subscription: any = await register.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(VAPID_PUBLIC_KEY),
    });


    const token = `Bearer ${localStorage.getItem("ontym-access-token")}`

    const res = await fetch(`${apis.BASE_URL}push/subscription`, {
      method: "POST",
      body: JSON.stringify(subscription),
      headers: {
        "content-type": "application/json",
        "Authorization": `${token}`,
      },
    });

    const data = await res.json();
  };

  // Call the handleServiceWorker function
  handleServiceWorker();
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
