import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PositionState {
  positions: any[];
  roles: any[];
  email: string;
  password?: string;
  first_name: string;
  last_name: string;
  phone: string;
  primary_position?: number;
  parent_user_id?: number;
  id?: number;
  dob: string;
  employee_id: string;
  is_active: "ACTIVE" | "INACTIVE" | "PENDING";
  selectedPositionIds?: any[];
  selectedRoleIds?: any[];
}

const initialState: PositionState = {
  email: "",
  password: "",
  first_name: "",
  last_name: "",
  phone: "",
  positions: [],
  roles: [],
  primary_position: -1,
  parent_user_id: -1,
  dob: "",
  employee_id: "",
  is_active: "PENDING",
  selectedPositionIds: [],
  selectedRoleIds: [],
};

export const userCreateSlice = createSlice({
  name: "userCreate",
  initialState,
  reducers: {
    createUser: (state, action: PayloadAction<PositionState>) => {
      return (state = action.payload);
    },

    // positions slice
    addPositionsToUser: (state, action: PayloadAction<object>) => {
      state.positions.push(action.payload);
    },

    addAllPositionsToUser: (state, action: PayloadAction<any>) => {
      state.positions = action.payload;
    },

    updatePositionByIndex: (state, action: PayloadAction<any>) => {
      const { index, position } = action.payload;
      const newPositions = [...state.positions];
      newPositions[index] = position;
      return {
        ...state,
        positions: newPositions,
      };
    },

    setPrimaryPosition: (state, action: PayloadAction<any>) => {
      const { id } = action.payload;

      return {
        ...state,
        primary_position: id,
      };
    },

    deletePositionByIndex: (state, action: PayloadAction<number>) => {
      state.positions.splice(action.payload, 1);
    },

    // roles slice
    addRolesToUser: (state, action: PayloadAction<object>) => {
      state.roles.push(action.payload);
    },

    addAllRolesToUser: (state, action: PayloadAction<any>) => {
      state.roles = action.payload;
    },

    updateRoleByIndex: (state, action: PayloadAction<any>) => {
      const { index, role } = action.payload;
      const newRoles = [...state.roles];
      newRoles[index] = role;
      return {
        ...state,
        roles: newRoles,
      };
    },

    deleteRoleByIndex: (state, action: PayloadAction<number>) => {
      state.roles.splice(action.payload, 1);
    },

    setUserActiveOrInactive: (state, action: PayloadAction<any>) => {
      const { is_active } = action.payload;
      return {
        ...state,
        is_active,
      };
    },

    setSelectedPositionIds: (state, action: PayloadAction<any>) => {
      state.selectedPositionIds = action.payload;
    },
    setSelectedRoleIds: (state, action: PayloadAction<any>) => {
      state.selectedRoleIds = action.payload;
    },
  },
});

// export the user slice
export const {
  createUser,
  addPositionsToUser,
  addRolesToUser,
  deletePositionByIndex,
  updatePositionByIndex,
  setPrimaryPosition,
  updateRoleByIndex,
  deleteRoleByIndex,
  addAllPositionsToUser,
  addAllRolesToUser,
  setUserActiveOrInactive,
  setSelectedPositionIds,
  setSelectedRoleIds,
} = userCreateSlice.actions;
export default userCreateSlice.reducer;
