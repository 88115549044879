import React from "react";
import { useSelector } from "react-redux";
import "./APILoader.less";

export default function APILoader() {
  const loader = useSelector((state) => state.loader);
  return (
    <>
      {loader ? (
        <div className="loader-wrapper">
          <div className="progress-bar">
            <span className="bar">
              <span className="progress"></span>
            </span>
          </div>
        </div>
      ) : null}
    </>
  );
}
