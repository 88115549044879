import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const positionSlice = createSlice({
  name: "position",
  initialState,
  reducers: {
    setPosition: (state, action: PayloadAction<object>) => {
      return (state = action.payload);
    },
    addRowToPosition: (state, action: PayloadAction<object>) => {
      //  add a new row to the position array
      return (state = {
        ...state,
        skills: [...state.skills, action.payload],
      });
    },
    removeRowToPosition: (state, action: PayloadAction<number>) => {
      //  add a new row to the position array
      return (state = {
        ...state,
        skills: state.skills.filter(
          (s: any, i: number) => i !== action.payload
        ),
      });
    },
    setSkillToRowByIndex: (state, action: PayloadAction<any>) => {
      //  set a skill to a row by index
      const { index, skill } = action.payload;
      const newSkills = [...state.skills];
      newSkills[index] = { skill };
      return {
        ...state,
        skills: newSkills,
      };
    },

    setSkillToRow: (state, action: PayloadAction<any>) => {
      //  set a skill to a row in the position array

      return (state = {
        ...state,
        skills: state.skills.map((skill: any) => {
          if (skill.id === action.payload.id) {
            return action.payload;
          }
          return skill;
        }),
      });
    },
    setPrimaryPosition: (state, action: PayloadAction<string>) => {
      //  set the primary position
      return (state = {
        ...state,
        primaryPosition: action.payload,
      });
    },
  },
});
// export the user slice
export const {
  setPosition,
  addRowToPosition,
  removeRowToPosition,
  setSkillToRow,
  setSkillToRowByIndex,
  setPrimaryPosition,
} = positionSlice.actions;
export default positionSlice.reducer;
