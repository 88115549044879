import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {};

export const roles = createSlice({
  name: "role",
  initialState,
  reducers: {
    setRole: (state, action: PayloadAction<object>) => {
      return (state = action.payload);
    },
    addRowToRoles: (state, action: PayloadAction<object>) => {
      //  add a new row to the position array
      return (state = {
        ...state,
        skills: [...state.skills, action.payload],
      });
    },
  },
});
// export the user slice
export const { setRole, addRowToRoles } = roles.actions;
export default roles.reducer;
