import React, { useEffect, useState } from "react";
import "./Style.less";
import LoadingSpinner from "../shared/LoadingSpinner";

export default function GlobalLoader() {
  const [loaderPercent, setLoaderPercent] = useState(0);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (loaderPercent < 100) {
          setLoaderPercent(loaderPercent + 1);
        } else {
          setLoaderPercent(0);
        }
      },
      loaderPercent > 95 ? 500 : 50
    );
    return () => clearInterval(interval);
  }, [loaderPercent]);

  const loading = (percent) => {
    if (percent > 95) {
      return `${loaderPercent}%, Almost there!`;
    } else {
      return `Loading ${loaderPercent}%`;
    }
  };

  const logoStyles  = {
    width: '50px', 
    objectFit: 'cover',  
    marginBottom:'-32px',
  };

  return (
    <div className="ontym-loader">
      <div className="ontym-loader-inner">
        <img src="/images/croppedTriangles.png" style={logoStyles} alt="Loading..." />
        <LoadingSpinner /> 
        {/* <div className="ontym-loader-bar">
          <div className="filled" style={{ width: `${loaderPercent}%` }}></div>
        </div>
        {loaderPercent === 100 ? (
          <p>{"Done :)"}</p>
        ) : (
          <p>{loading(loaderPercent)}</p>
        )} */}
      </div>
    </div>
  );
}
