import { createSlice } from '@reduxjs/toolkit'

const initialState: any = {
    tasks: [],
    isOpen: false,
}


const TaskReallocationSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTasks: (state, action) => {
            state.tasks = action.payload;
        },
        setIsopen: (state, action) => {
            state.isOpen = action.payload;
        }
    }
})


export const { setTasks, setIsopen } = TaskReallocationSlice.actions;
export default TaskReallocationSlice.reducer