import { createUpdateRoleBody } from "./../utils/StateTypes";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apis from "../apis/url";

export const rolesApi = createApi({
  reducerPath: "roles",
  baseQuery: fetchBaseQuery({
    baseUrl: apis.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // const token = (getState() as RootState).auth.token
      const token = JSON.parse(localStorage.getItem("OnTym-Token") || "{}");
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    allRolesOrganization: builder.query<any, void>({
      query: () => ({
        url: `/role/get-all-organization-roles`,
        method: "GET",
      }),
    }),
    allRolesUser: builder.query<any, number>({
      query: (user_id) => ({
        url: `/role/get-all-user-roles?user_id=${user_id}`,
        method: "GET",
      }),
    }),
    createUpdateRoles: builder.mutation<any, createUpdateRoleBody>({
      query: (requestBody) => ({
        url: `/role/create-or-update-role`,
        method: "GET",
        body: requestBody,
      }),
    }),
  }),
});

export const {
  useAllRolesOrganizationQuery,
  useAllRolesUserQuery,
  useCreateUpdateRolesMutation,
} = rolesApi;
