import {
  GetPlanPricingBody,
  PlanPricingResponse,
  Step1Info,
  UserDetailsResponse,
} from "./../utils/StateTypes";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apis from "../apis/url";
export const purchaseApi = createApi({
  reducerPath: "plan-purchase",
  baseQuery: fetchBaseQuery({
    baseUrl: apis.BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      // const token = (getState() as RootState).auth.token
      const token = JSON.parse(localStorage.getItem("OnTym-Token") || "{}");
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    purchaseStepOne: builder.mutation<any, Step1Info>({
      query: (requestBody) => ({
        url: "/plan/purchase/step1",
        method: "POST",
        body: requestBody,
      }),
    }),
    purchaseStepTwo: builder.mutation<any, { name: string }>({
      query: (requestBody) => ({
        url: "/plan/purchase/step2",
        method: "POST",
        body: requestBody,
      }),
    }),
    verifyPhoneOtp: builder.mutation<any, string>({
      query: (otp) => ({
        url: `/auth/verify-phone?otp=${otp}`,
        method: "GET",
      }),
    }),
    verifyEmailOtp: builder.mutation<any, string>({
      query: (otp) => ({
        url: `/auth/verify-email?otp=${otp}`,
        method: "GET",
      }),
    }),
    getUserDetails: builder.query<UserDetailsResponse, void>({
      query: () => ({
        url: `/auth/userdetails`,
        method: "GET",
      }),
    }),
    getPlanPricing: builder.query<PlanPricingResponse, GetPlanPricingBody>({
      query: (body) => ({
        url: `/plan/pricing`,
        method: "POST",
        body,
      }),
    }),
    addOrganization: builder.mutation<any, { name: string }>({
      query: (body) => ({
        url: `/plan/purchase/step2`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePurchaseStepOneMutation,
  usePurchaseStepTwoMutation,
  useVerifyEmailOtpMutation,
  useVerifyPhoneOtpMutation,
  useGetUserDetailsQuery,
  useGetPlanPricingQuery,
} = purchaseApi;
